






































import {Component, Vue, Prop, Watch} from "vue-property-decorator";
import {NorthStarPaginator} from "@/typescript/types";
import {GetCompletedNortStarCount} from "@/graphql/NorthStar";
import WidgetTemplate from "@/components/general/WidgetTemplate.vue";
import SmartModal from "@/components/general/SmartModal.vue";
import NorthStarData from "@/components/northstars/NorthStarData.vue";

@Component({
  components: {SmartModal, WidgetTemplate}
})
export default class DashboardCompletedNorthstar extends Vue {
  completedNorthstarLists: NorthStarPaginator | null = null;

  @Prop({required: true, default: null})
  startDate!: Date

  @Prop({required: true, default: null})
  endDate!: Date

  @Prop({required: false, default: null})
  userId!: String

  @Prop({required: false, default: false})
  inModal!: boolean;

  @Prop({required: true})
  origin!: string

  @Prop({required: true})
  originId!: string

  page: number = 0;

  get workspace(){
    return this.$store.state.workspace;
  }

  selfModalOpen() {
    // animation: 'animated fadeInLeft faster',
    this.$buefy.modal.open({
      component: DashboardCompletedNorthstar,
      //@ts-ignore
      customClass: 'smart-modal animate-left',
      props: {
        inModal: true,
        startDate: this.startDate,
        endDate: this.endDate,
        userId: this.$props.userId,
        origin: this.$props.origin,
        originId: this.$props.originId
      },
      parent: this,
    });
  }

  getCompletedNorthStar() {
    this.$apollo.query({
      query: GetCompletedNortStarCount,
      fetchPolicy: 'network-only',
      variables: {
        workspace_id: (this.$props.origin == 'workspace') ? this.$props.originId : this.workspace.id,
        board_id: (this.$props.origin == 'board') ? this.$props.originId : null,
        start_date: this.$props.startDate,
        end_date: this.$props.endDate,
        userId: this.$props.userId,
        page: this.page

      }
    }).then((result: any) => {
      if(this.completedNorthstarLists) {
        this.completedNorthstarLists.data = this.filterDuplicateData([...this.completedNorthstarLists.data, ...result.data.getCompletedNorthStarOnDateRange.data]);
        this.completedNorthstarLists.paginatorInfo = result.data.getCompletedNorthStarOnDateRange.paginatorInfo;
      }else{
        this.completedNorthstarLists = result.data.getCompletedNorthStarOnDateRange;
      }

      
    })
  }

  filterDuplicateData(arr: Array<any>) {
    return arr.filter((v,i,a)=>a.findIndex(v2=>(v2.id===v.id))===i)
  }

  openNorthStarAsModal(id: string, board_id: string) {
    this.$buefy.modal.open({
      component: NorthStarData,
      props: {
        onmodal: true,
        editable: true,
        showMilestones: true,
        board: board_id,
        id: id
      },
      width: '780px',
      animation: 'none',
      customClass: 'northstar-detailed is-paddingless',
      // @ts-ignore
      parent: this,
    });
  }

  changePage() {
    this.page++;
    this.getCompletedNorthStar();
  }

  mounted() {
    this.page++;
    this.getCompletedNorthStar();

    this.$events.listen('northstars-updated-for-northstar-completed-count', (eventData: any) => {

      if(eventData.status === 'completed') {
        this.completedNorthstarLists!.data.push(eventData);
        this.completedNorthstarLists!.paginatorInfo.total = this.completedNorthstarLists!.paginatorInfo.total+1;
      }else{
        let index = this.completedNorthstarLists!.data.findIndex((ns: any) => ns.id === eventData.id);

        if(index != -1) {

          this.completedNorthstarLists!.data.splice(index, 1);
          this.completedNorthstarLists!.paginatorInfo.total = this.completedNorthstarLists!.paginatorInfo.total-1;
        }
      }

    })
  }

  @Watch('startDate', {deep: true})
  completedAtChange() {
    this.getCompletedNorthStar();
  }

  @Watch('userId', {deep: true})
  userIdChange() {
    this.getCompletedNorthStar();
  }

  @Watch('originId', {deep: true})
  originIdChange() {
    this.getCompletedNorthStar();
  }


}
